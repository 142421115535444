import { useAccount, useMsal } from "@azure/msal-react";
import { Box, Button, HStack, TabPanel } from "@chakra-ui/react";
import { ReactGrid } from "@silevis/reactgrid";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import * as FileSaver from "file-saver";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { useRef } from "react";
import { BsCardList } from "react-icons/bs";
import { MdOutlineCancel, MdOutlineTableRows } from "react-icons/md";
import { IoAddCircleOutline } from "react-icons/io5";
import { RxReset } from "react-icons/rx";

import { findMaxKey, getDeepCopy } from "../../../../Constants";
import useFetch from "../../../../hooks/useFetchWithMsal";
import CustomAlertDialogBox from "../../../CustomAlertDialogBox/CustomAlertDialogBox";
import SaveButton from "../../../Save";
import RegionalLeaderTable from "../RegionalLeaderTable";
// import TextWrapRenderer from './TextWrapRenderer';
import ChangeLog from "../../../ChangeLog/ChangeLog";
import { getCellClassName } from "../RegionalCanvasHelper";
import "../style.scss";

export default function DropDownTables({
  setDataSaved,
  ActiveEnv,
  CustomerMasterRows,
  CustomerRowMapping,
  SupplySkuNonEditableRows,
  SupplySkuColumnsDatabase,
  DropDownRows,
  DropDownMasters,
  DropDownMasterColumnsMapping,
  dependenciesMapping,
  blankRows = [],
  DropDownMasterIntRows,
  DropDownMasterDateRows = [],
  retrieveURL,
  updateURL,
  deleteURL,
  deletenabled: isDeleteEnabled = true,
  warehouseCalculatedColumns = [],
  warehouseCalculatedColumnsMapping = {},
  warehouseCalculatedColumnsOperations = {},
  ItemMasterGroupPolicy = {},
  additionalNotes = [],
  sortByFields = [],
  id_sheet,
  pathname,
  fetchData,
  disableVirtualScrolling = false,
}) {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const prettySheets = JSON.parse(sessionStorage.getItem("prettySheets"));
  const [initialData, setInitialData] = useState(false);
  const [int1, setInt] = useState(0);
  const navigate = useNavigate();
  const [dataGrouped, setDataGrouped] = useState({});
  const [dataGroupedOriginal, setDataGroupedOriginal] = useState({});
  const [DropDownMaster, setDropDownMaster] = useState({});
  const [maxID, setMaxID] = useState((prev) => {
    let maxKey = findMaxKey(dataGrouped);
    return dataGrouped ? (isNaN(maxKey) ? 0 : maxKey + 1) : 0;
  });
  const [maxIDInDB, setMaxIDInDB] = useState((prev) => {
    let maxKey = findMaxKey(dataGrouped);
    return dataGrouped ? (isNaN(maxKey) ? 0 : maxKey + 1) : 0;
  });

  const [, updateData] = useFetch([]);
  const [, deleteData] = useFetch();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [changesTracker, setChangesTracker] = useState({});
  const [deleteTracker, setDeleteTracker] = useState({});
  const [comment, setComment] = useState("");
  const [deleteCheckBox, setDeleteCheckBox] = useState(false);
  const [isAlertBoxOpen, setIsAlertBoxOpen] = useState(false);
  const [alertBoxType, setAlertBoxType] = useState(null);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [gridData, setGridData] = useState({});

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const toastIdRef = useRef();

  const getOptionType = useCallback(
    (DropDownMaster) => {
      if (Object.keys(DropDownMaster).length === 0) return {};
      var masterMappingDict = {};
      DropDownMasters.forEach((masterMappings) => {
        masterMappingDict[masterMappings] = Object.entries(
          DropDownMaster[masterMappings]
        ).map(([key, value]) => {
          return { label: `${key}`, value: `${key}` };
        });
      });

      return masterMappingDict;
    },
    [DropDownMasters]
  );

  const [optionType, setOptionType] = useState({});

  const handleChanges = (changes) => {
    setInt(int1 + 1);

    if (
      changes[0].type === "dropdown" &&
      changes[0].newCell.text === changes[0].previousCell.text
    ) {
      setRows((rowsTemp) => {
        let dataRow = rows.find((trial1) => trial1.rowId === changes[0].rowId);
        dataRow["cells"][
          columns.findIndex(
            (columnId) => columnId["columnId"] === changes[0]["columnId"]
          )
        ]["isOpen"] = changes[0].newCell.isOpen;
        return [...rowsTemp];
      });
    } else if (
      changes[0].type === "checkbox" &&
      changes[0].newCell.checked !== changes[0].previousCell.checked
    ) {
      setRows((rowsTemp) => {
        let dataRow = rows.find((trial1) => trial1.rowId === changes[0].rowId);
        dataRow["cells"][
          columns.findIndex(
            (columnId) => columnId["columnId"] === changes[0]["columnId"]
          )
        ]["checked"] = changes[0].newCell.checked;
        return [...rowsTemp];
      });
      setDeleteTracker((prevData) => {
        var updatedData = prevData;
        var rowstodelete = updatedData["rowstodelete"]
          ? updatedData["rowstodelete"]
          : [];
        if (changes[0].newCell.checked === true) {
          if (rowstodelete.indexOf(changes[0].rowId) === -1) {
            rowstodelete.push(changes[0].rowId);
          }
        } else {
          const indextoremove = rowstodelete.indexOf(changes[0].rowId);
          if (indextoremove !== -1) {
            rowstodelete.splice(indextoremove, 1);
          }
        }
        updatedData["rowstodelete"] = rowstodelete;
        return updatedData;
      });
    } else {
      setIsButtonDisabled(false);
      setDataSaved(false);
      if (
        (changes[0].newCell.text === "" || changes[0].newCell.text === null) &&
        blankRows.findIndex((value207) => value207 === changes[0].columnId) !==
          -1 &&
        changes[0].columnId !== "Comment" &&
        changes[0].columnId !== "Status" &&
        changes[0].columnId !== "Lot Number"
      ) {
        const toastSettings = {
          description: changes[0].columnId + " cannot be left blank",
          status: "error",
          isClosable: true,
        };
        if (toastIdRef.current) {
          toast.update(toastIdRef.current, toastSettings);
        } else {
          toast(toastSettings);
        }
      } else {
        let dataTemp = dataGrouped;
        changes.forEach((change) => {
          if (parseInt(change.rowId) > maxID) {
            let dictionaryToSet = {};
            dictionaryToSet[CustomerRowMapping["ID"]] = parseInt(change.rowId);
            if (
              DropDownMasterIntRows.findIndex(
                (value369) => value369 === change.columnId
              ) === -1
            ) {
              dictionaryToSet[CustomerRowMapping[change.columnId]] =
                change.newCell.text;
            } else {
              dictionaryToSet[CustomerRowMapping[change.columnId]] =
                change.newCell.value;
            }
            var Poer = change.columnId;
            CustomerMasterRows.filter(
              (value) => !(value === Poer || value === "ID")
            ).forEach((value) => {
              dictionaryToSet[CustomerRowMapping[value]] = "";
            });
            if (
              DropDownRows.findIndex((value) => value === change.columnId) !==
              -1
            ) {
              dependenciesMapping[change.columnId].forEach((keyofmapping) => {
                dictionaryToSet[keyofmapping] = DropDownMaster[
                  DropDownMasterColumnsMapping[
                    CustomerRowMapping[change.columnId]
                  ]
                ][change.newCell.text][keyofmapping]
                  ? DropDownMaster[
                      DropDownMasterColumnsMapping[
                        CustomerRowMapping[change.columnId]
                      ]
                    ][change.newCell.text][keyofmapping]
                  : "";
              });
            }
            setChangesTracker((prevChanges) => {
              const updatedChanges = { ...prevChanges };
              var dictionaryToSend = {};
              SupplySkuColumnsDatabase.forEach((value2) => {
                dictionaryToSend[value2] = dictionaryToSet[value2];
              });
              let newCustomer = change.rowId >= maxIDInDB ? true : false;
              const key = `${ActiveEnv},${change.rowId},${newCustomer}`;
              const existingChanges = updatedChanges[
                `${ActiveEnv},${change.rowId},${newCustomer}`
              ]
                ? updatedChanges[`${ActiveEnv},${change.rowId},${newCustomer}`]
                : {};
              let dataChanged = existingChanges["DataChanged"]
                ? existingChanges["DataChanged"]
                : [];
              if (
                dataChanged.findIndex(
                  (value252) => value252 === CustomerRowMapping[change.columnId]
                ) === -1
              ) {
                dataChanged.push(CustomerRowMapping[change.columnId]);
              }
              dictionaryToSend["DataChanged"] = dataChanged;
              updatedChanges[key] = dictionaryToSend;
              return updatedChanges;
            });

            dataTemp[parseInt(change.rowId)] = dictionaryToSet;
          } else {
            let dictionaryToSet = dataTemp[change.rowId];
            if (
              DropDownMasterIntRows.findIndex(
                (value369) => value369 === change.columnId
              ) === -1
            ) {
              dictionaryToSet[CustomerRowMapping[change.columnId]] =
                change.newCell.text;
            } else {
              dictionaryToSet[CustomerRowMapping[change.columnId]] =
                change.newCell.value;
            }
            if (
              DropDownRows.findIndex((value) => value === change.columnId) !==
              -1
            ) {
              dependenciesMapping[change.columnId].forEach((keyofmapping) => {
                let mainDict =
                  DropDownMaster[
                    DropDownMasterColumnsMapping[
                      CustomerRowMapping[change.columnId]
                    ]
                  ];
                dictionaryToSet[keyofmapping] = mainDict[change.newCell.text]
                  ? mainDict[change.newCell.text][keyofmapping]
                    ? mainDict[change.newCell.text][keyofmapping]
                    : ""
                  : dataGroupedOriginal[parseInt(change.rowId)][keyofmapping]
                  ? dataGroupedOriginal[parseInt(change.rowId)][keyofmapping]
                  : "";
              });
            }

            // Update Safety Stock Policy as per filter fields
            if (
              Object.keys(ItemMasterGroupPolicy).indexOf(change.columnId) > -1
            ) {
              const filterFieldList = ItemMasterGroupPolicy[change.columnId];
              const filterValueList = ItemMasterGroupPolicy[
                change.columnId
              ].map((filter) => dataTemp[change.rowId][filter]);
              const updateFieldList = [...dependenciesMapping[change.columnId]];
              updateFieldList.push(CustomerRowMapping[change.columnId]);

              const updateFieldValueList = [
                ...updateFieldList.map(
                  (field) => dataTemp[change.rowId][field]
                ),
              ];

              Object.keys(dataTemp).forEach((key) => {
                const currFilterValueList = [
                  ...filterFieldList.map((field) => dataTemp[key][field]),
                ];

                if (
                  filterValueList.toString() === currFilterValueList.toString()
                ) {
                  updateFieldList.forEach((field, idx) => {
                    dataTemp[key][field] = updateFieldValueList[idx];
                  });
                }
              });
            }

            dataTemp[parseInt(change.rowId)] = dictionaryToSet;
            setChangesTracker((prevChanges) => {
              const updatedChanges = { ...prevChanges };
              var dictionaryToSend = {};

              let newCustomer = change.rowId >= maxIDInDB ? true : false;
              const key = `${ActiveEnv},${change.rowId},${newCustomer}`;
              const existingChanges = updatedChanges[
                `${ActiveEnv},${change.rowId},${newCustomer}`
              ]
                ? updatedChanges[`${ActiveEnv},${change.rowId},${newCustomer}`]
                : {};
              let updated_columns = existingChanges["DataChanged"]
                ? existingChanges["DataChanged"]
                : [];
              let existingChanges_id = existingChanges["id"]?existingChanges["id"]:parseInt(change.rowId)
              let old_dictionary = existingChanges["old_dictionary"] ?? {};
              SupplySkuColumnsDatabase.forEach((value2) => {
                dictionaryToSend[value2] = dictionaryToSet[value2];
                if (!newCustomer) {
                  old_dictionary[value2] =
                    dataGroupedOriginal[change.rowId][value2];
                }
              });

              var array1 = [];
              if (
                DropDownRows.findIndex(
                  (value471) => value471 === change.columnId
                ) !== -1
              ) {
                dependenciesMapping[change.columnId].forEach((dependency) => {
                  if (
                    SupplySkuColumnsDatabase.findIndex(
                      (dbcolumn) => dbcolumn === dependency
                    ) !== -1
                  ) {
                    array1 = [...array1, dependency];
                  }
                });
              }

              if (
                SupplySkuColumnsDatabase.findIndex(
                  (value147) => value147 === CustomerRowMapping[change.columnId]
                ) !== -1
              ) {
                array1 = [...array1, CustomerRowMapping[change.columnId]];
              }
              updated_columns =
                updated_columns.findIndex(
                  (value1236) =>
                    value1236 === CustomerRowMapping[change.columnId]
                ) === -1
                  ? [...updated_columns, ...array1]
                  : updated_columns;
              dictionaryToSend["DataChanged"] = updated_columns;
              dictionaryToSend["old_dictionary"] = old_dictionary;
              dictionaryToSend["id"] = existingChanges_id
              updatedChanges[key] = dictionaryToSend;

              return updatedChanges;
            });
          }
        });
        setDataGrouped(dataTemp);
        let maxKey = findMaxKey(dataTemp);
        setMaxID(isNaN(maxKey) ? 0 : maxKey + 1);
        setRows(
          getRows(dataTemp, dataGroupedOriginal, optionType, deleteCheckBox)
        );
      }
    }
    // console.log(dataGrouped,changesTracker)
  };

  const getColumns = useCallback(
    (data, deleteCheckBox1) => {
      // if (Object.keys(data).length === 0) return [];
      var CustomerMasterRows1 = CustomerMasterRows;
      if (deleteCheckBox1 === true) {
        CustomerMasterRows1 = ["Delete", ...CustomerMasterRows];
      }
      return [
        ...CustomerMasterRows1.map((column) => {
          return {
            columnId: column,
            width:
              Math.max(
                ...[
                  column.length,
                  ...Object.values(data).map(
                    (d) => `${d[CustomerRowMapping[column]]}`.length
                  ),
                ]
              ) * (DropDownRows.indexOf(column) > -1 ? 15 : 10),
            resizable: true,
          };
        }),
        ...warehouseCalculatedColumns.map((column) => {
          return {
            columnId: column,
            width: column.length * 10,
            resizable: true,
          };
        }),
      ];
    },
    [
      CustomerMasterRows,
      CustomerRowMapping,
      DropDownRows,
      warehouseCalculatedColumns,
    ]
  );

  const getRows = useCallback(
    (pivotedData, dataGroupedOriginal, optionType, deleteCheckBox1) => {
      if (Object.keys(optionType).length === 0 && DropDownRows.length !== 0)
        return [];

      var CustomerMasterRows1 = CustomerMasterRows;
      if (deleteCheckBox1 === true) {
        CustomerMasterRows1 = ["Delete", ...CustomerMasterRows];
      }

      const headerRowCells = [
        ...CustomerMasterRows1.map((column) => {
          return { type: "header", text: column, className: "header-cell " };
        }),
        ...warehouseCalculatedColumns.map((column) => {
          return { type: "header", text: column, className: "header-cell " };
        }),
      ];

      let rows = [
        {
          rowId: "header",
          cells: [...headerRowCells],
        },
      ];

      let dictionaryRow = [...rows];
      Object.entries(pivotedData)
        .sort(([key1, val1], [key2, val2]) => {
          if (sortByFields.length > 0) {
            const compExprResult = [];
            sortByFields.map((field) =>
              compExprResult.push(
                val1[field].localeCompare(val2[field]).toString()
              )
            );
            return eval(compExprResult.join(" || "));
          } else {
            return 0;
          }
        })
        .forEach(([parentRowName, value1]) => {
          let oneSingleRow = {
            rowId: `${parentRowName}`,
            cells: [
              ...CustomerMasterRows1.map((value) => {
                if (
                  DropDownRows.findIndex((value1) => value1 === value) !== -1
                ) {
                  let dropdownOption =
                    optionType[
                      DropDownMasterColumnsMapping[CustomerRowMapping[value]]
                    ];
                  return {
                    type: "dropdown",
                    selectedValue: `${value1[CustomerRowMapping[value]]}`,
                    isOpen: false,
                    values: dataGroupedOriginal[parentRowName]
                      ? dropdownOption.findIndex(
                          (value4) =>
                            value4["label"] ===
                            dataGroupedOriginal[parentRowName][
                              CustomerRowMapping[value]
                            ]
                        ) !== -1
                        ? dropdownOption
                        : [
                            {
                              label: `${
                                dataGroupedOriginal[parentRowName][
                                  CustomerRowMapping[value]
                                ]
                              }`,
                              value: `${
                                dataGroupedOriginal[parentRowName][
                                  CustomerRowMapping[value]
                                ]
                              }`,
                            },
                            ...dropdownOption,
                          ]
                      : dropdownOption,
                    className: "editable-cell ",
                  };
                } else if (value === "Delete") {
                  return {
                    type: "checkbox",
                    className: "pale-red-bg",
                    checked: false,
                  };
                } else {
                  if (
                    DropDownMasterDateRows.findIndex(
                      (value6) => value6 === value
                    ) !== -1
                  ) {
                    return {
                      type: "date",
                      date: value1[CustomerRowMapping[value]]
                        ? new Date(value1[CustomerRowMapping[value]])
                        : "",
                      className: getCellClassName(
                        SupplySkuNonEditableRows,
                        value
                      ),
                      nonEditable:
                        SupplySkuNonEditableRows.findIndex(
                          (value1) => value1 === value
                        ) !== -1,
                    };
                  } else if (
                    DropDownMasterIntRows.findIndex(
                      (value6) => value6 === value
                    ) === -1
                  ) {
                    return {
                      type: "text",
                      text: `${value1[CustomerRowMapping[value]] ?? ""}`,
                      className: getCellClassName(
                        SupplySkuNonEditableRows,
                        value
                      ),
                      nonEditable:
                        SupplySkuNonEditableRows.findIndex(
                          (value1) => value1 === value
                        ) !== -1,
                    };
                  } else {
                    return {
                      type: "number",
                      value: value1[CustomerRowMapping[value]],
                      className: getCellClassName(
                        SupplySkuNonEditableRows,
                        value
                      ),
                      nonEditable:
                        SupplySkuNonEditableRows.findIndex(
                          (value1) => value1 === value
                        ) !== -1,
                    };
                  }
                }
              }),
              ...warehouseCalculatedColumns.map((column) => {
                let value2 = warehouseCalculatedColumnsOperations[
                  column
                ].reduce(
                  (prev, opr) => {
                    if (opr === "sum") {
                      return warehouseCalculatedColumnsMapping[column].reduce(
                        (prev2, col1) => {
                          return value1[CustomerRowMapping[col1]] === "" ||
                            isNaN(value1[CustomerRowMapping[col1]]) ||
                            !value1[CustomerRowMapping[col1]]
                            ? prev2
                            : prev2 + value1[CustomerRowMapping[col1]];
                        },
                        0
                      );
                    } else if (opr === "mean") {
                      let count = 0;
                      let value4 = warehouseCalculatedColumnsMapping[
                        column
                      ].reduce((prev2, col1) => {
                        if (
                          !(
                            col1 === "" ||
                            isNaN(value1[CustomerRowMapping[col1]]) ||
                            !value1[CustomerRowMapping[col1]]
                          )
                        ) {
                          count++;
                        }
                        return col1 === "" ||
                          isNaN(value1[CustomerRowMapping[col1]]) ||
                          !col1
                          ? prev2
                          : value1[CustomerRowMapping[col1]] + prev2;
                      }, 0);
                      value4 = value4 / count;
                      return value4;
                    } else if (opr.includes("divide=")) {
                      let value_to_divide = opr.split("=")[1];
                      let value_to_d = parseFloat(value_to_divide);
                      return prev / value_to_d;
                    } else {
                      return prev;
                    }
                  },
                  DropDownMasterIntRows.findIndex(
                    (value6) => value6 === column
                  ) !== -1
                    ? 0
                    : ""
                );
                return {
                  type:
                    DropDownMasterIntRows.findIndex(
                      (value6) => value6 === column
                    ) !== -1
                      ? "number"
                      : "text",
                  value:
                    DropDownMasterIntRows.findIndex(
                      (value6) => value6 === column
                    ) !== -1
                      ? parseFloat(value2.toFixed(0))
                      : value2,
                  nonEditable: true,
                  className: "non-editable-cell ",
                };
              }),
            ],
          };
          dictionaryRow.push(oneSingleRow);
        });
      let maxKey = findMaxKey(pivotedData);
      // dictionaryRow.push({
      //   rowId: `${isNaN(maxKey)?1:maxKey+1}`,
      //   cells: [
      //     ...CustomerMasterRows1.map((value) => {
      //       if (Object.keys(ItemMasterGroupPolicy).indexOf(value) > -1) {
      //         return {
      //           type: "text",
      //           nonEditable: true,
      //           text: "Disabled",
      //           className: "editable-cell",
      //         };
      //       } else if (
      //         DropDownRows.findIndex((value1) => value1 === value) !== -1
      //       ) {
      //         return {
      //           type: "dropdown",
      //           isOpen: false,
      //           values:
      //             optionType[
      //               DropDownMasterColumnsMapping[CustomerRowMapping[value]]
      //             ],
      //           className: "editable-cell ",
      //         };
      //       } else if (value === "ID") {
      //         return {
      //           type: "text",
      //           text: `${findMaxKey(pivotedData) + 1}`,
      //           className: getCellClassName(SupplySkuNonEditableRows, value),
      //           nonEditable:
      //             SupplySkuNonEditableRows.findIndex(
      //               (value1) => value1 === value
      //             ) !== -1,
      //         };
      //       } else if (value === "Delete") {
      //         return { type: "text", className: "non-editable-cell", text: "" };
      //       } else {
      //         if (
      //           DropDownMasterDateRows.findIndex(
      //             (value6) => value6 === value
      //           ) !== -1
      //         ) {
      //           return {
      //             type: "date",
      //             date: '',
      //             className: getCellClassName(
      //               SupplySkuNonEditableRows,
      //               value
      //             ),
      //             nonEditable:
      //               SupplySkuNonEditableRows.findIndex(
      //                 (value1) => value1 === value
      //               ) !== -1,
      //           };
      //         }else if (
      //           DropDownMasterIntRows.findIndex(
      //             (value6) => value6 === value
      //           ) === -1
      //         ) {
      //           return {
      //             type: "text",
      //             text: "",
      //             className: getCellClassName(SupplySkuNonEditableRows, value),
      //             nonEditable:
      //               SupplySkuNonEditableRows.findIndex(
      //                 (value1) => value1 === value
      //               ) !== -1,
      //           };
      //         } else {
      //           return {
      //             type: "number",
      //             value: null,
      //             className: getCellClassName(SupplySkuNonEditableRows, value),
      //             nonEditable:
      //               SupplySkuNonEditableRows.findIndex(
      //                 (value1) => value1 === value
      //               ) !== -1,
      //           };
      //         }
      //       }
      //     }),
      //     ...warehouseCalculatedColumns.map((column) => {
      //       return {
      //         type:
      //           DropDownMasterIntRows.findIndex(
      //             (value6) => value6 === column
      //           ) !== -1
      //             ? "number"
      //             : "text",
      //         value: null,
      //         nonEditable: true,
      //         className: "non-editable-cell ",
      //       };
      //     }),
      //   ],
      // });

      return dictionaryRow;
    },
    [
      CustomerRowMapping,
      DropDownMasterColumnsMapping,
      DropDownRows,
      SupplySkuNonEditableRows,
      CustomerMasterRows,
      DropDownMasterIntRows,
      DropDownMasterDateRows,
      warehouseCalculatedColumns,
      warehouseCalculatedColumnsOperations,
      warehouseCalculatedColumnsMapping,
      ItemMasterGroupPolicy,
      sortByFields,
    ]
  );

  const handleColumnResize = (ci, width) => {
    if (Object.keys(dataGrouped).length === 0) return [];
    setColumns((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  useEffect(() => {
    if (!account) {
      navigate("/");
    } else {
      if (!initialData && ActiveEnv && Object.keys(dataGrouped).length === 0) {
        setInitialData(true);
        fetchData(retrieveURL, { method: "GET" })
          .then((data) => {
            setGridData(getDeepCopy(data));
            setDataGrouped(data["ExistingTable"]);
            setDataGroupedOriginal(getDeepCopy(data["ExistingTable"]));
            setDropDownMaster(
              data["DropdownMasters"] ? data["DropdownMasters"] : {}
            );
            const temp = getOptionType(
              data["DropdownMasters"] ? data["DropdownMasters"] : {}
            );
            setOptionType(temp);
            setColumns(getColumns(data["ExistingTable"], deleteCheckBox));
            setRows(
              getRows(
                data["ExistingTable"],
                data["ExistingTable"],
                temp,
                deleteCheckBox
              )
            );
            let maxKey = findMaxKey(data["ExistingTable"]);
            setMaxID(isNaN(maxKey) ? 0 : maxKey + 1);
            setMaxIDInDB(isNaN(maxKey) ? 0 : maxKey + 1);
          })
          .catch((error) => {
            console.error(`Error: ${error}`);
          });

        setDataSaved(true);
      }
    }
  }, [
    account,
    navigate,
    ActiveEnv,
    dataGrouped,
    dataGroupedOriginal,
    setDataSaved,
    retrieveURL,
    getRows,
    initialData,
    getColumns,
    getOptionType,
    deleteCheckBox,
    fetchData,
  ]);

  const uploadData = () => {
    setIsButtonDisabled(true);
    let data = [];
    let blankSpace = [];
    Object.entries(changesTracker).forEach(([key, value1]) => {
      Object.entries(value1)
        .filter(
          ([key4, value4]) =>
            !(
              key4 === "Comment" ||
              key4 === "lot_no" ||
              key4 === "status" ||
              blankRows.findIndex((value140) => value140 === key4) !== -1
            )
        )
        .forEach(([key, value]) => {
          if (value === null || value === "") {
            blankSpace.push([value1[CustomerRowMapping["ID"]], key]);
          }
        });
    });

    if (blankSpace.length > 0) {
      let ErrorMsg = "";
      blankSpace.forEach((value) => {
        ErrorMsg =
          ErrorMsg +
          "Blank Space Found for ID " +
          value[0] +
          " for " +
          value[1] +
          ". ";
      });
      const toastSettings = {
        description: ErrorMsg,
        status: "error",
        isClosable: true,
      };
      if (toastIdRef.current) {
        toast.update(toastIdRef.current, toastSettings);
      } else {
        toast(toastSettings);
      }
    } else {
      Object.entries(changesTracker).forEach(([key, newValue]) => {
        const [, ID, newCustomer] = key.split(",");
        data.push({
          updatedDict: newValue,
          ID: ID,
          newCustomer: newCustomer,
          comment: comment,
          PageName: pathname.split("-")[0],
        });
      });
      updateData(updateURL, { method: "PUT", body: data })
        .then((data) => {
          if (data.Error) return;
        })
        .then(() => {
          // updateTotals(totals, pivotedData, setTotals, setPivotedData, changesTracker)
          setDataSaved(true);
          setDataGroupedOriginal(getDeepCopy(dataGrouped));
          let maxKey = findMaxKey(dataGrouped);
          setMaxIDInDB(isNaN(maxKey) ? 0 : maxKey + 1);
          setChangesTracker({});
          setIsAlertBoxOpen(!isAlertBoxOpen);
          setAlertBoxType(null);
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    }
  };

  const deleteSelectedRows = async () => {
    const rowstodelete = deleteTracker["rowstodelete"];
    let dataGrouped1 = dataGrouped;
    let dataGroupedOriginal1 = { ...dataGroupedOriginal };
    let changesTracker1 = { ...changesTracker };
    const rowstodatabase = rowstodelete.filter((value) => value < maxIDInDB);
    if (rowstodatabase.length > 0) {
      let data = {};
      data["rowstodeleteindatabase"] = rowstodatabase;
      data["deleterow"] = true;
      data["PageName"] = pathname.split("-")[0];
      data["comment"] = comment;
      await deleteData(deleteURL, { method: "DELETE", body: data })
        .then((data) => {
          if (data.Error) return;
        })
        .then(() => {
          rowstodelete.forEach((value147) => {
            delete dataGrouped1[value147];
            delete dataGroupedOriginal1[value147];
            let newCustomer = value147 >= maxIDInDB ? true : false;
            delete dataGrouped1[value147];
            delete changesTracker1[`${ActiveEnv},${value147},${newCustomer}`];
          });
          if (rowstodatabase.indexOf(`${maxIDInDB - 1}`) !== -1) {
            let maxKey = findMaxKey(dataGrouped1);
            setMaxIDInDB(isNaN(maxKey) ? 0 : maxKey + 1);
            setMaxID(isNaN(maxKey) ? 0 : maxKey + 1);
          }
          setDataGrouped(dataGrouped1);
          setDataGroupedOriginal(dataGroupedOriginal1);
        });
    } else {
      const toastSettings = {
        description: "Data Deleted Successfully",
        status: "success",
        duration: 3000,
      };
      if (toastIdRef.current) {
        toast.update(toastIdRef.current, toastSettings);
      } else {
        toast(toastSettings);
      }
      rowstodelete.forEach((value147) => {
        let newCustomer = true;
        delete dataGrouped1[value147];
        delete changesTracker1[`${ActiveEnv},${value147},${newCustomer}`];
      });
      setDataGrouped(dataGrouped1);
      setDataGroupedOriginal(dataGroupedOriginal1);
      let maxKey = findMaxKey(dataGrouped1);
      setMaxID(isNaN(maxKey) ? 0 : maxKey + 1);
    }

    setDeleteTracker((prevData) => {
      prevData["rowstodelete"] = [];
      return prevData;
    });
    setChangesTracker(changesTracker1);
    setDeleteCheckBox(!deleteCheckBox);
    setColumns(getColumns(dataGrouped1, !deleteCheckBox));
    setRows(
      getRows(dataGrouped1, dataGroupedOriginal, optionType, !deleteCheckBox)
    );
    setIsAlertBoxOpen(!isAlertBoxOpen);
    setAlertBoxType(null);
  };

  const handleDeleteCheckbox = () => {
    if (!deleteCheckBox === false) {
      setDeleteTracker((prevData) => {
        prevData["rowstodelete"] = [];
        return prevData;
      });
    }
    setDeleteCheckBox(!deleteCheckBox);
    setColumns(getColumns(dataGrouped, !deleteCheckBox));
    setRows(
      getRows(dataGrouped, dataGroupedOriginal, optionType, !deleteCheckBox)
    );
  };

  /**
   * Add new row handler for ReactGrid
   * @param {*} event Button click event.
   */
  const addNewRowHandler = (event) => {
    const updatedGroupedData = { ...dataGrouped };

    const maxKey = findMaxKey(dataGrouped);
    const newMaxKey = isNaN(maxKey) ? 0 : maxKey + 1;
    const newData = JSON.parse(
      JSON.stringify({ ...updatedGroupedData[maxKey] })
    );

    Object.keys(newData).forEach((key) => {
      if (key === "ID") {
        newData[key] = newMaxKey;
      } else {
        newData[key] = "";
      }
    });

    updatedGroupedData[newMaxKey] = getDeepCopy(newData);

    const optionType = getOptionType(gridData["DropdownMasters"] || {});

    setMaxID(newMaxKey);
    setDataGrouped(updatedGroupedData);
    setDataGroupedOriginal(updatedGroupedData);
    setRows(
      getRows(
        updatedGroupedData,
        updatedGroupedData,
        optionType,
        deleteCheckBox
      )
    );
    setColumns(getColumns(updatedGroupedData, deleteCheckBox));
    setChangesTracker((prevChanges) => {
      const updatedChanges = { ...prevChanges };
      const dictionaryToSend = {};
      const newCustomer = newMaxKey >= maxIDInDB ? true : false;
      const key = `${ActiveEnv},${newMaxKey},${newCustomer}`;

      const existingChanges =
        updatedChanges[`${ActiveEnv},${newMaxKey},${newCustomer}`] || {};
      const dataChanged = existingChanges["DataChanged"] || [];
      dictionaryToSend["DataChanged"] = dataChanged;
      updatedChanges[key] = dictionaryToSend;

      return { ...updatedChanges };
    });
  };

  /**
   * Reset change handler for ReactGrid
   * @param {*} event  Button click event
   */
  const resetCellChangesHandler = (event) => {
    const optionType = getOptionType(gridData["DropdownMasters"] || {});
    const maxKey = findMaxKey(gridData["ExistingTable"]);
    const newMaxKey = isNaN(maxKey) ? 0 : maxKey + 1;

    setDataGrouped(getDeepCopy(gridData["ExistingTable"]));
    setDataGroupedOriginal(getDeepCopy(gridData["ExistingTable"]));
    setDropDownMaster(gridData["DropdownMasters"] || {});
    setOptionType(optionType);
    setColumns(getColumns(gridData["ExistingTable"], deleteCheckBox));
    setRows(
      getRows(
        gridData["ExistingTable"],
        gridData["ExistingTable"],
        optionType,
        deleteCheckBox
      )
    );
    setMaxID(newMaxKey);
    setChangesTracker({});
    setIsButtonDisabled(true);
    setIsAlertBoxOpen(false);
    setAlertBoxType(null);
    setDataSaved({});
  };

  // if (loading || !ActiveEnv ||!customer_name_filter )
  // if (error) return <p>Follwing Error has occurred {error}</p>;

  // .filter(([filterKey,filterValue])=>(customer_name_filter==="All"|filterValue[CustomerRowMapping["Customer Name"]]===customer_name_filter|filterValue[CustomerRowMapping["Customer Name"]]==="")&&(filterValue[CustomerRowMapping["Channel"]]===channel_filter|filterValue[CustomerRowMapping["Channel"]]==="")&&(filterValue[CustomerRowMapping["SOP Category"]]===sop_category_filter|filterValue[CustomerRowMapping["SOP Category"]]===""))
  return (
    <TabPanel>
      <HStack spacing={4}>
        <SaveButton
          isButtonDisabled={isButtonDisabled}
          uploadData={() => {
            setIsAlertBoxOpen(!isAlertBoxOpen);
            setAlertBoxType("Save");
          }}
        />

        <Button
          style={{ marginTop: "16px", width: "130px" }}
          fontSize="md"
          fontWeight="bold"
          onClick={resetCellChangesHandler}
          colorScheme="red"
          leftIcon={<RxReset />}
          isDisabled={!Object.keys(changesTracker).length}
        >
          Reset changes
        </Button>

        <Button
          style={{ marginTop: "16px", width: "130px" }}
          fontSize="md"
          fontWeight="bold"
          onClick={onOpen}
          colorScheme="blue"
          leftIcon={<BsCardList />}
        >
          Change Logs
        </Button>

        <Button
          style={{ marginTop: "16px", width: "130px" }}
          fontSize="md"
          fontWeight="bold"
          onClick={addNewRowHandler}
          colorScheme="blue"
          leftIcon={<IoAddCircleOutline />}
        >
          Add Row
        </Button>

        {isDeleteEnabled && (
          <>
            {!deleteCheckBox && (
              <Button
                style={{ marginTop: "16px", width: "130px" }}
                fontSize="md"
                fontWeight="bold"
                onClick={handleDeleteCheckbox}
                colorScheme="red"
                leftIcon={<MdOutlineTableRows />}
              >
                Delete Rows
              </Button>
            )}
            {deleteCheckBox && (
              <Button
                style={{ marginTop: "16px", width: "190px" }}
                fontSize="md"
                fontWeight="bold"
                onClick={() => {
                  setIsAlertBoxOpen(!isAlertBoxOpen);
                  setAlertBoxType("Rows");
                }}
                colorScheme="red"
                leftIcon={<MdOutlineTableRows />}
              >
                Delete selected rows
              </Button>
            )}
            {deleteCheckBox && (
              <Button
                style={{ marginTop: "16px", width: "190px" }}
                fontSize="md"
                fontWeight="bold"
                onClick={handleDeleteCheckbox}
                colorScheme="yellow"
                leftIcon={<MdOutlineCancel />}
              >
                Cancel Delete Rows
              </Button>
            )}
          </>
        )}

        {additionalNotes.length > 0 && (
          <Box
            position="absolute"
            right="10%"
            bg="yellow.100"
            padding="2"
            borderRadius="10"
            border="1px"
            borderStyle="solid"
            borderColor="grey"
          >
            <p>
              <b>Note:</b> {additionalNotes.join(" ")}
            </p>
          </Box>
        )}

        {prettySheets && (
          <Box position="absolute" right="2%">
            <RegionalLeaderTable
              name={
                prettySheets.filter(
                  (value) => value[0] === parseInt(id_sheet)
                )[0][1]
              }
            />
          </Box>
        )}
      </HStack>

      <Box minHeight="10vh" paddingBottom={10}>
        <Box
          maxHeight="60vh"
          paddingBottom="20vh"
          overflow="auto"
          textAlign="center"
          id="printableArea"
          className="react-grid"
        >
          {/* <ReactGrid rows={rows} columns={columns} onCellsChanged={handleChanges} stickyTopRows={1} stickyLeftColumns={1} disableVirtualScrolling={true} stickyRightColumns={1} onColumnResized={handleColumnResize} enableColumnSelection enableRangeSelection enableRowSelection></ReactGrid> */}
          <div id="reactgrid-red" className="react-grid-min-height">
            <ReactGrid
              rows={rows}
              columns={columns}
              stickyTopRows={1}
              stickyLeftColumns={deleteCheckBox ? 1 : 0}
              onColumnResized={handleColumnResize}
              onCellsChanged={handleChanges}
              enableColumnSelection
              enableRangeSelection
              enableRowSelection
              disableVirtualScrolling={disableVirtualScrolling}
            ></ReactGrid>
          </div>
        </Box>
      </Box>

      {isAlertBoxOpen && (
        <CustomAlertDialogBox
          isAlertBoxOpen={isAlertBoxOpen}
          setIsAlertBoxOpen={setIsAlertBoxOpen}
          themeColor={alertBoxType === "Save" ? "green" : "red"}
          headerMsg={
            alertBoxType === "Save"
              ? "Do you want to save thees changes to the database?"
              : "Delete selected rows"
          }
          bodyMsg={"Are you sure? You can't undo this action afterwards."}
          onOKHandler={
            alertBoxType === "Save" ? uploadData : deleteSelectedRows
          }
          onChangeCommentHandler={setComment}
        />
      )}

      {isOpen && (
        <ChangeLog
          PageName={pathname.split("-")[0]}
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
        />
      )}
    </TabPanel>
  );
}
