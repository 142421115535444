import Container_Capacity from "./documentation/slicer_specific/Container_Capacity.pdf";
import Documentation_In_Progress from "./documentation/slicer_specific/Documentation_In_Progress.pdf";
import Inventory_Warehouse_Mapping from "./documentation/slicer_specific/Inventory_Warehouse_Mapping.pdf";
import Location_To_Warehouse_Mapping from "./documentation/slicer_specific/Location_To_Warehouse_Mapping.pdf";
import Order_SKU_Fulfillment_SKU_Mapping from "./documentation/slicer_specific/Order_SKU_Fulfillment_SKU_Mapping.pdf";
import Product_Supply_SKU_Mapping from "./documentation/slicer_specific/Product_Supply_SKU_Mapping.pdf";
import Warehouse_Master from "./documentation/slicer_specific/Warehouse_Master.pdf";
import Warehouse_SKU_Mapping from "./documentation/slicer_specific/Warehouse_SKU_Mapping.pdf";
import Warehouse_Space from "./documentation/slicer_specific/Warehouse_Space.pdf";
import WOS_Exceptions from "./documentation/slicer_specific/WOS_Exceptions.pdf";

export let API_URL;
if (process.env.REACT_APP_NODE_ENV === "production") {
  const HOSTNAME = "prettylitter.valuechainmetrics.com";
  // const HOSTNAME = "localhost:8001";
  API_URL = `https://${HOSTNAME}/api`;
} else {
  const HOSTNAME = "localhost:7071";
  API_URL = `https://${HOSTNAME}/api`;
}

export const VERSION = `3.1.0`;

export let REDIRECT_URI;

if (process.env.REACT_APP_NODE_ENV === "production") {
  // Use the production API URL with https
  REDIRECT_URI = `https://${window.location.host}/`;
} else if (process.env.REACT_APP_NODE_ENV === "testing") {
  // Use the localhost API URL with http
  REDIRECT_URI = `http://${window.location.host}/`;
} else {
  // Use the localhost API URL with http
  REDIRECT_URI = `http://${window.location.host}/`;
}

export const PRODUCT_NAME = "PrettyLitter Analyzer Control Tables";

export const MSALCONFIG = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority:
      "https://login.microsoftonline.com/" +
      process.env.REACT_APP_TENANT_ID +
      "/",
    redirectUri: `${REDIRECT_URI}`,
  },
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

export const loginRequest = {
  forceRefresh: false,
};

export const ApplyCellBg = (type) =>
  type === "qty_override_forecast" ||
  type === "distributor_current_forecast" ||
  type === "consultant_current_forecast" ||
  type === "revenue_current_forecast" ||
  type === "show_current_forecast" ||
  type === "Current Fcst" ||
  type === "Calc Current Fcst"
    ? {
        backgroundColor: "#f5deb3",
        borderTop: "2px solid lightGray",
        fontWeight: "bold",
      }
    : {
        borderTop: "2px solid lightGray",
      };

export const FormatDateToMonthYear = (dateStr) => {
  const date = new Date(dateStr.replace(/-/g, "/"));
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  return `${month}-${year}`;
};

export const protectedResources = {
  scopes: [
    "https://planvida-api-prettylitter.valuechainplanning.com/api.ReadWrite",
  ],
};

export function findMaxKey(obj) {
  const numericKeys = Object.keys(obj).map(Number);

  if (numericKeys.length === 0) {
    // Handle empty object case
    return undefined;
  }

  return Math.max(...numericKeys);
}

export function findMaxId(arrayOfDicts, key) {
  // Initialize the maximum splitId to a minimum value
  let maxSplitId = Number.MIN_SAFE_INTEGER;

  // Iterate through the array and update maxSplitId if a higher value is found
  arrayOfDicts.forEach(([k1, item]) => {
    const splitId = item[key] || 0; // Get the value for 'splitId' field, default to 0 if not present
    maxSplitId = Math.max(maxSplitId, parseFloat(splitId));
  });

  return maxSplitId;
}

/**
 * Returns the deep copy of an object.
 * @param {Object} obj Object to create copy of.
 * @returns {Object} Deep copied object.
 */
export function getDeepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export const pdfMapping = {
  "Item Master": Documentation_In_Progress,
  "Customer Master": Documentation_In_Progress,
  "Warehouse Master": Warehouse_Master,
  "S&OP Category Master": Documentation_In_Progress,
  "Supply SKU Master": Documentation_In_Progress,
  "New Conversion Master": Documentation_In_Progress,
  "Customer Warehouse Split": Documentation_In_Progress,
  "Demand SKU Table": Documentation_In_Progress,
  "Warehouse SKU Mapping": Warehouse_SKU_Mapping,
  "Inventory Warehouse Mapping": Inventory_Warehouse_Mapping,
  "Order SKU Fullfillment SKU Mapping": Order_SKU_Fulfillment_SKU_Mapping,
  "Order Warehouse Mapping": Documentation_In_Progress,
  "Discontinuated Warehouse": Documentation_In_Progress,
  "Warehouse Space": Warehouse_Space,
  "Warehouse Capacity Constraints": Documentation_In_Progress,
  "WOS Exceptions": WOS_Exceptions,
  "Container Capacity": Container_Capacity,
  "Product Supply SKU Mapping": Product_Supply_SKU_Mapping,
  "Location To Warehouse Mapping": Location_To_Warehouse_Mapping,
};
